<template>
  <div>
    <v-form v-model="form" ref="form">
      <auto-completar
        v-if="renewal"
        v-model="vehiculo"
        :items="vehicles"
        item-text="datos.tituloPrecio"
        item-value="datos.patente"
        clearable
        is-outline
        color="info"
        return-object
        :outlined="!!vehiculo"
        hide-selected
        required
        :rules="[(v) => !!v || 'Campo Requerido']"
        placeholder="¿Qué vehículo te interesa comprar?"
      ></auto-completar>
      <h2
        v-if="renewal"
        style="text-align: center"
        class="title--text font-weight-light"
      >
        Ingresa la patente de tu vehículo
      </h2>
      <text-dinamico
        v-model="ppu"
        :required="true"
        placeholder="Patente"
        is-outline
        :message="message"
        :new-rules="[
          (v) =>
            /^[^AEIOUaeiou0-9]{4}[0-9]{2}$/.test(v) ||
            'La patente debe contener 4 letras (no vocales) seguidas por 2 números',
          (v) => !!v || 'Campo Requerido',
        ]"
        @blur="getInfo(ppu)"
      />
      <auto-completar
        v-if="isValidVehicle"
        v-model="ano"
        :required="true"
        placeholder="Año"
        :items="local.years"
        :is-disabled="isVehiclePpuDetail"
        :loading="!years.length"
        is-outline
      />
      <auto-completar
        v-if="isValidVehicle"
        v-model="marca"
        :required="true"
        placeholder="Marca"
        :items="local.brands"
        :loading="Boolean(!local.brands.length && ano)"
        :is-disabled="isVehiclePpuDetail"
        itemText="name"
        itemValue="name"
        returnObject
        is-outline
      />
      <auto-completar
        v-if="isValidVehicle"
        v-model="modelo"
        :disabled="!local.models.length || !marca"
        :required="true"
        :items="local.models"
        :loading="Boolean(!local.models.length && marca)"
        :is-disabled="isVehiclePpuDetail && local.models.length === 1"
        placeholder="Modelo"
        itemText="name"
        itemValue="name"
        is-outline
        returnObject
      />
      <auto-completar
        v-if="isValidVehicle"
        v-model="version"
        :required="true"
        placeholder="Version"
        :items="local.versions"
        :loading="Boolean(!local.versions.length && modelo)"
        :is-disabled="isVehiclePpuDetail && local.versions.length === 1"
        itemText="name"
        itemValue="name"
        returnObject
        is-outline
      />

      <auto-completar
        v-if="isValidVehicle && Boolean(version)"
        v-model="combustible"
        :required="true"
        placeholder="Combustible"
        :is-disabled="!marca || disableFuel"
        is-outline
        :items="fuels"
        itemText="name"
        itemValue="id"
        returnObject
      />
      <auto-completar
        v-if="isValidVehicle && Boolean(version)"
        v-model="transmition"
        :required="true"
        placeholder="Transmisión"
        :is-disabled="!marca || disableTransmition"
        is-outline
        :items="formTransmition"
        itemText="name"
        itemValue="id"
        returnObject
      />
      <auto-completar
        v-if="isValidVehicle && Boolean(version)"
        v-model="traction"
        :required="true"
        placeholder="Tracción"
        :is-disabled="!marca || disableTraction"
        is-outline
        :items="formTractions"
        itemText="name"
        itemValue="id"
        returnObject
      />
      <text-dinamico
        v-model="kilometraje"
        required
        is-outline
        placeholder="Kilometraje"
        type="number"
        :new-rules="[
          (v) => v < 90000 || `El kilometraje debe ser menor a 90.000kms`,
          (v) => !!v || 'Campo Requerido',
          (v) => v > 100 || `El kilometraje debe tener 3 digitos`,
        ]"
      />
      <auto-completar
        v-if="Boolean(kilometraje)"
        v-model="owner"
        required
        placeholder="N° de dueños"
        :disabled="!owners.length"
        is-outline
        :items="owners"
      />
      <auto-completar
        v-if="Boolean(kilometraje)"
        v-model="maintenance"
        placeholder="Última mantención realizada en la marca"
        :disabled="!maintenances.length"
        is-outline
        :items="maintenances"
      />
      <text-dinamico
        v-if="Boolean(kilometraje)"
        v-model="precio"
        is-outline
        placeholder="Precio esperado"
        :required="consignment"
        type="number"
        :new-rules="dynamicRules"
      />
      <p v-if="Boolean(kilometraje) && consignment">¿Tiene Prenda?</p>
      <v-radio-group
        v-if="Boolean(kilometraje) && consignment"
        v-model="prenda"
        :error-messages="messageRadioButton"
        row
        class="mb-2"
      >
        <v-radio label="Si" value="si" color="red" />
        <v-radio label="No" value="no" color="info" />
      </v-radio-group>
      <text-dinamico
        v-if="Boolean(kilometraje)"
        v-model="nombre"
        :required="true"
        placeholder="Nombre"
        :new-rules="[
          (v) =>
            (v && v.length >= 2) ||
            'El Nombre debe tener al menos 2 caracteres',
        ]"
        is-outline
      />
      <text-dinamico
        v-if="Boolean(kilometraje)"
        v-model="apellido"
        :required="true"
        placeholder="Apellidos"
        :new-rules="[
          (v) =>
            (v && v.length >= 2) ||
            'El Apellido debe tener al menos 2 caracteres',
        ]"
        is-outline
      />
      <text-dinamico
        v-if="Boolean(kilometraje)"
        v-model="telefono"
        :required="true"
        placeholder="Teléfono"
        is-outline
        :new-rules="[
          (v) =>
            (v && v.length >= 12) ||
            'El Teléfono debe tener al menos 12 caracteres',
          (v) =>
            /^(?:\+?56)?(?:\s?)(?:9\d{8}|2\d{7}|[3-4]\d{7}|[5-7]{1}[2-9]{1}\d{6})$/.test(
              v
            ) || 'Telefono Invalido',
        ]"
      />
      <auto-completar
        v-if="
          Boolean(kilometraje) && Boolean(telefono && telefono.length === 12)
        "
        v-model="contact"
        placeholder="Contacto de preferencia"
        :disabled="!contacts.length"
        is-outline
        :items="contacts"
      />
    </v-form>
    <v-col cols="12">
      <v-row
        align="center"
        :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'end'"
        no-gutters
      >
        <v-btn
          color="info"
          :disabled="!ppu"
          :loading="formSend"
          @click="cotizar"
        >
          Cotizar
        </v-btn>
      </v-row>
    </v-col>
    <v-snackbar :timeout="5000" v-model="snackbar" color="info">
      <v-row justify="center" align="center" no-gutter>
        <v-icon left color="white">{{ iconMensaje }}</v-icon>
        {{ snackbarMensaje }}
      </v-row>
    </v-snackbar>
    <v-dialog v-model="openGif" persistent width="220">
      <v-img :src="gif" />
    </v-dialog>
    <v-dialog v-model="dialog" width="410">
      <v-card>
        <v-card-title class="text-h5 info--text">
          <v-row
            align="center"
            justify="center"
            no-gutters
            class="font-weight-bold"
          >
            ¡Gracias por su tiempo!
          </v-row>
        </v-card-title>
        <v-card-text class="mt-3 text-subtitle-1 text-justify">
          Gracias, ya recibimos la información y estamos procesando su
          solicitud.<br />
          Un ejecutivo le contactará para darle nuestra oferta <br />
          Si no es lo que espera, dígaselo a su ejecutivo para ver cuánto
          podemos mejorarla.
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TextDinamico from '@/components/textDinamico';
import {mapActions, mapMutations, mapState} from 'vuex';
import AutoCompletar from '@/components/core/autoCompletar.vue';
import {isValidLicensePlate, oldPlate} from '@/utils/vehiculo';
import {normalizeString} from '@/utils/general';

export default {
  name: 'formulariopetersenCompra',
  components: {AutoCompletar, TextDinamico},
  props: {
    consignment: {
      type: Boolean,
      default: false,
    },
    renewal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    openGif: false,
    gif: require('@/assets/petersen ppu.gif'),
    local: {
      brands: [],
      models: [],
      versions: [],
      years: [],
    },
    messageRadioButton: '',
    disableFuel: false,
    disableTraction: false,
    disableTransmition: false,
    formSend: false,
    purchase: null,
    dialog: false,
    snackbar: false,
    iconMensaje: '',
    message: '',
    snackbarMensaje: '',
    nombre: null,
    apellido: null,
    correo: '',
    marca: null,
    ppu: null,
    modelo: null,
    kilometraje: null,
    ano: null,
    version: null,
    combustible: null,
    form: false,
    prenda: null,
    telefono: '+569',
    traction: null,
    transmition: null,
    precio: null,
    owners: ['1', '2', '3', '4', '5', '+5'],
    owner: null,
    maintenance: null,
    vehicleSelected: null,
    vehiculo: '',
    maintenances: [
      'No tiene',
      '5.000 kms',
      '10.000 kms',
      '15.000 kms',
      '20.000 kms',
      '25.000 kms',
      '30.000 kms',
      '35.000 kms',
      '40.000 kms',
      '45.000 kms',
      '50.000 kms',
      '55.000 kms',
      '60.000 kms',
      '65.000 kms',
      '70.000 kms',
      '75.000 kms',
      '80.000 kms',
      '85.000 kms',
      '90.000 kms',
    ],
    contact: null,
    contacts: ['💬 Whatsapp', '📱 Teléfono'],
  }),
  mounted() {
    this.GET_YEARS();
  },
  computed: {
    ...mapState([
      'brands',
      'models',
      'versiones',
      'fuels',
      'years',
      'formTransmition',
      'formTractions',
      'correoSend',
      'vehiclePpuDetail',
      'attributes',
      'stockGeneral',
    ]),
    dynamicRules() {
      const rules = [
        () =>
          this.precio >= 1000000 ||
          `Monto ingresado debe tener más de 6 dígitos`,
        () =>
          this.precio <= 99999999 ||
          `Monto ingresado debe tener menos de 9 dígitos`,
      ];

      if (this.consignment) {
        rules.unshift(() => !!this.precio || 'Este campo es requerido');
      }

      return rules;
    },
    isValidVehicle() {
      const {isVehiclePpuDetail, openGif, ppu} = this;

      if (!ppu || ppu.length !== 6) return false;
      if (!openGif && !oldPlate(ppu)) return true;
      if (oldPlate(ppu)) return false;

      return (
        !isVehiclePpuDetail &&
        ppu &&
        ppu.length === 6 &&
        isValidLicensePlate(ppu)
      );
    },
    isVehiclePpuDetail() {
      return (
        Boolean(this.vehiclePpuDetail) && Boolean(this.vehiclePpuDetail.year)
      );
    },
    attributesAndVehicleEquipment() {
      const {attributes, fuels, formTransmition, formTractions} = this;

      return {
        attributes,
        fuels,
        formTransmition,
        formTractions,
      };
    },
    vehicles() {
      if (!this.stockGeneral || !this.stockGeneral.length) return [];
      return this.stockGeneral.filter((auto) => {
        const arr = auto.datos.status.split(' ');

        arr.shift();

        const status = arr.join(' ');

        return status !== 'RESERVADO' && status !== 'VENTA EN PROCESO';
      });
    },
  },
  watch: {
    prenda: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.messageRadioButton = '';
        }
        if (val === 'si') {
          this.messageRadioButton =
            'Con prenda no lo podemos consignar, pero si comprar con pago inmediato';
          this.snackbar = true;
          this.iconMensaje = 'warning';
          this.snackbarMensaje =
            'En este caso no se puede consignar, pero si comprar.';
        }
      },
    },
    attributesAndVehicleEquipment: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.attributes && val.attributes.length) {
          const fuel = val.attributes.find(
            (v) => v.component === 'Combustible'
          );

          this.combustible = this.fuels.find(
            (f) => normalizeString(f.name) === normalizeString(fuel.value)
          );
          if (this.combustible) {
            this.disableFuel = true;
          }

          const transmition = val.attributes.find(
            (v) => v.component === 'Transmisión'
          );

          this.transmition = this.formTransmition.find((trans) => {
            if (transmition.value === 'Mecánica') {
              return trans.name === 'Manual';
            } else {
              return trans.name === 'Automatica';
            }
          });

          if (this.transmition) {
            this.disableTransmition = true;
          }

          const traction = val.attributes.find(
            (v) => v.component === 'Tracción'
          );

          this.traction = this.formTractions.find((trac) => {
            if (
              traction &&
              traction.value &&
              trac &&
              normalizeString(trac.name) === normalizeString(traction.value)
            ) {
              return true;
            }

            if (
              traction &&
              traction.value &&
              trac &&
              normalizeString(traction.value) === '4x2 y 4x4'
            ) {
              return trac.name === '4X4';
            }
          });

          if (this.traction) {
            this.disableTraction = true;
          }

          if (
            !this.combustible ||
            !this.combustible.id ||
            !this.transmition ||
            !this.transmition.id
          ) {
            const error = {
              app_name: `Web Petersen`,
              view: `Formulario ${
                this.consignment ? 'Consignaciones' : 'Compras'
              }`,
              record_id: `Patente: ${this.ppu.toUpperCase()}`,
              context: `La version ${JSON.stringify(
                this.version
              )} No hizo MATCH con los valores preestablecidos de combustible o transmisión`,
            };

            this.NOTIFY_ERROR(error);
          }
        }
      },
    },
    version: {
      immediate: true,
      handler(val) {
        if (val && val.id) {
          this.GET_ATTRIBUTES({version: val, year: this.ano});
        }
      },
    },
    ppu: {
      immediate: true,
      handler() {
        this.message = '';
        this.combustible = null;
        this.traction = null;
        this.transmition = null;
        this.version = null;
        this.modelo = null;
        this.marca = null;
        this.ano = null;
        this.SET_ATTRIBUTES(null);
      },
    },
    ano: {
      immediate: true,
      handler(val) {
        if (val) this.GET_BRANDS(val);
      },
    },
    brands: {
      immediate: true,
      handler(val) {
        if (val && val.length && !this.isVehiclePpuDetail)
          this.local.brands = val;
      },
    },
    models: {
      immediate: true,
      handler(val) {
        if (val && val.length && !this.isVehiclePpuDetail)
          this.local.models = val;
      },
    },
    versiones: {
      immediate: true,
      handler(val) {
        if (val && val.length) this.local.versions = val;
      },
    },
    vehiclePpuDetail: {
      immediate: true,
      deep: true,
      handler(val) {
        this.openGif = false;
        if (val && val.brand && (!val.models || !val.versions)) {
          const error = {
            app_name: `Web Petersen`,
            view: `Formulario ${
              this.consignment ? 'Consignaciones' : 'Compras'
            }`,
            record_id: `Patente: ${this.ppu.toUpperCase()}`,
            context: `Ingresando la patente no encontro los valores necesarios para procesarla`,
          };
          this.NOTIFY_ERROR(error);
        }

        if (val) {
          this.local.brands = val.brand ? [val.brand] : [];
          this.local.models = val.models ? val.models : [];
          this.local.years = val.year
            ? [val.year]
            : this.years.map((year) => year.name);

          this.ano = val.year;
          this.marca = val.brand;
          if (val.models && val.models.length === 1) {
            this.modelo = val.models[0];
          }
          if (val.versions && val.versions.length === 1) {
            this.version = val.versions[0];
          }
        } else {
          this.local.brands = [];
          this.local.models = [];
          this.local.versions = [];
          this.marca = null;
          this.modelo = null;
          this.version = null;
          this.ano = null;
          this.local.years = this.years.map((year) => year.name);
          this.GET_BRANDS(this.ano);
        }
      },
    },
    marca: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && !this.isVehiclePpuDetail) {
          this.GET_MODELS({year: this.ano, brand: val});
        }
      },
    },
    modelo: {
      immediate: true,
      deep: true,
      handler(val) {
        this.local.versions = [];
        this.GET_VERSIONS({year: this.ano, model: val});
      },
    },
    dialog(val) {
      if (!val) {
        this.SET_CORREOSEND(val);
        this.$router.push({name: 'petersen'}).catch(() => {});
      }
    },
    correoSend: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.formSend = false;
          this.dialog = val;
          this.SET_CORREOSEND(false);
        }
      },
    },
    vehicles: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.length) {
          const {
            $route: {
              query: {vehicle},
            },
          } = this;
          if (!vehicle) return;

          const search = val.find(
            (auto) => auto.id.toString() === vehicle.toString()
          );

          if (search) {
            this.vehiculo = search;
          }
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'SEND_RENEWAL_GENIO',
      'SEND_SELL_AIRTABLE',
      'SEND_SELL_GENIO',
      'SEND_BUY_GENIO',
      'GET_BRANDS',
      'GET_MODELS',
      'GET_VERSIONS',
      'GET_FUELS',
      'GET_YEARS',
      'GET_TRACTION',
      'GET_TRANSMITION',
      'GET_PPU_INFO',
      'GET_ATTRIBUTES',
      'NOTIFY_ERROR',
    ]),
    isValidLicensePlate,
    ...mapMutations([
      'SET_CORREOSEND',
      'SET_VEHICLE_PPU_DETAIL',
      'SET_ATTRIBUTES',
    ]),
    cotizar() {
      if (this.renewal && !this.vehiculo) {
        return;
      }

      if (this.consignment && this.prenda === null) {
        this.messageRadioButton = 'Debe seleccionar una opción';
        return;
      }

      if (!this.isValidLicensePlate(this.ppu) && !this.isVehiclePpuDetail) {
        this.message = 'Patente invalida';
        return;
      } else {
        this.message = '';
      }

      if (!this.form) {
        this.snackbar = true;
        this.iconMensaje = 'warning';
        this.snackbarMensaje = 'Debe Llenar todos los campos requeridos!!!';
        this.$refs.form.validate();
        return;
      }

      let vehicleId = null;

      if (this.vehiculo) {
        vehicleId = this.vehicles.find((item) => item.id === this.vehiculo.id)
          .id;
      }

      const cotizar = {
        ppu: this.ppu.toUpperCase(),
        ano: this.ano,
        marca: this.marca,
        modelo: this.modelo,
        version: this.version,
        combustible: this.combustible.id,
        fuel: this.combustible.name,
        transmition: this.transmition,
        traction: this.traction,
        kilometraje: this.kilometraje,
        owner: this.owner,
        maintenance: this.maintenance,
        price: this.precio,
        nombre: this.nombre,
        apellido: this.apellido,
        telefono: this.telefono,
        //-----------------------------
        url: 'automotrizpetersen.cl' + this.$route.path,
        tipo: 'compra',
        contact: this.contact,
        renew: vehicleId,
        prenda: this.prenda,
        consignment: this.consignment,
        vehiculo: this.vehiculo.id,
      };
      this.purchase = cotizar;

      if (this.renewal) {
        this.SEND_RENEWAL_GENIO(cotizar);
      } else {
        this.SEND_SELL_GENIO(cotizar);
      }

      this.formSend = true;
      this.$refs.form.reset();
    },
    whatsapp() {
      const link = `https://api.whatsapp.com/send?phone=+56944436444&text=¡Hola!%20Mi%20nombre%20es%20${this.purchase.nombre}%20${this.purchase.apellido},%20acabo%20de%20enviar%20una%20solicitud%20de%20tasación%20de%20mi%20auto%20desde%20la%20página%20web.%20¿Podrían%20confirmar%20si%20la%20recibieron%20y%20cuándo%20podré%20recibir%20una%20respuesta?%20Muchas%20gracias.`;
      window.open(link, '_blank');
    },
    getInfo(ppu) {
      this.message = '';
      if (oldPlate(ppu)) {
        this.message = 'No compramos vehículos menores al año 2010';
        return;
      }

      if (ppu && ppu.length === 6) {
        this.openGif = true;
        this.GET_PPU_INFO(ppu);
      } else {
        this.SET_VEHICLE_PPU_DETAIL(null);
      }
    },
  },
};
</script>

<style scoped></style>
